import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import Iconify from '../../components/Iconify';

import ContactUsbtn from '../../sections/contact/ContactUsBtn';

import ReviewsGoogleWidget from '../../sections/reviews/ReviewsGoogleWidget';

// ----------------------------------------------------------------------


const LINKS = [
  {
    headline: 'Main Menu',
    children: [
      { name: 'Accommodations', href: '/Accommodations' },
      { name: 'Food Menu', href: '/FoodMenu' },
      { name: 'Reviews', href: '/Reviews' },
      { name: 'Contact us', href: '/Contact' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'FAQs', href: '/FAQs' },
      { name: 'Privacy Policy', href: '/PrivacyPolicy' },
      { name: 'Terms and Condition', href: '/Terms&Conditions' },
      { name: 'Reservation Cancellation Policy', href: '/CancellationPolicy' },

      
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Call: +91 8100523877', href: 'tel:8100523877' },
      { name: 'Alternative: +91 8100523877', href: 'tel:8100523877' },
      { name: 'Whatsapp: +91 8100523877', href: 'https://api.whatsapp.com/send?phone=918100523877&text=Hi%2C%20I%20would%20like%20to%20know%20more%20about%20Tasher%20Ghor', target: '_blank' },
      { name: 'Mail: admin@tasherghor.com', href: 'mailto: admin@tasherghor.com' },
     
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));


// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const currentYear = new Date().getFullYear();

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }} display={'flex'} justifyContent={'center'}>
            {isLight ? (
              <img 
                src="https://ik.imagekit.io/tasherghor/TasherGhor/Logo/Tasher%20Ghor%20logo_english_black.png?updatedAt=1727261479807" 
                alt="Tasher Ghor Logo Black"
                height={60} 
                width={60} 
              />
            ) : (
              <img 
                src="https://ik.imagekit.io/tasherghor/TasherGhor/Logo/Tasher%20Ghor%20logo_english_white.png?updatedAt=1727261479893" 
                alt="Tasher Ghor Logo White"
                height={60} 
                width={60} 
              />
            )}
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2">
              {/* <Iconify icon={'basil:map-location-solid'} sx={{}} height={25} width={25} /> */}
              Mousuni Island, Near Namkhana, 24 Parganas South, PIN 743357, West Bengal, India. 743357
            </Typography>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
            <Typography component="p" variant="body2" sx={{ mt: 5, pb: 5, fontSize: 13, textAlign: { xs: 'center', md: 'left'},}}>
              © 2019-{currentYear} TASHERGHOR.COM
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
          <ContactUsbtn />
          <ReviewsGoogleWidget />
        
      </Container>
    </RootStyle>
  );
}
